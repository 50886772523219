import _size from "lodash/size"
import { equalTo, get, orderByChild, query, ref } from "firebase/database"
import { database, firebaseAuth } from "../../firebaseConfig"

export const getUserDetailsFromEmail = async email => {
  try {
    const usersRef = query(
      ref(database, `users`),
      ...[orderByChild("email"), equalTo(`${email}`)]
    )
    const snapshot = await get(usersRef)
    const data = snapshot.val()
    return data
  } catch (error) {
    return []
  }
}

export const isUserExistinDB = async email => {
  try {
    const userDetails = await getUserDetailsFromEmail(email)
    // console.log({ userDetails });
    return _size(userDetails) > 0
  } catch (error) {
    return false
  }
}

export const isEmailVerified = async () => {
  await firebaseAuth?.currentUser?.reload()
  return firebaseAuth?.currentUser?.emailVerified ?? false
}
